import React from 'react';
import { Layout, TermCountryNavigation, TermNavigation } from '@components/molecules';
import { TermsRichText } from '@components/atoms';
import { graphql } from 'gatsby';
import { Theme } from '@components/molecules/layout';
import styled from 'styled-components';
import {
  Terms_Of_Use_Page,
  Terms_Of_Use_PageBodyTerms_Of_Use,
} from '../../generated/prismicGraphql';

interface TermData {
  term_description: {
    raw: any[];
    text: string;
  };
  term_navigation_label: {
    raw: any[];
    text: string | null;
  };
  term_title: {
    raw: any[];
    text: string;
  };
}

interface Term {
  term_title: {
    text: string;
  };
  term_navigation_label?: {
    text: string | null;
  };
}

interface NavigationItem {
  text: string;
  url: string;
}

interface ExtendedTermsOfUseBody extends Terms_Of_Use_PageBodyTerms_Of_Use {
  items: TermData[];
}

interface TermsOfUsePageProps {
  data: {
    prismicTermsOfUsePage: {
      data: Terms_Of_Use_Page;
    };
  };
}

const SectionContainer = styled.div<{ isArabic?: boolean }>`
  padding: 2rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  text-align: ${(props) => (props.isArabic ? 'right' : 'left')};
`;
const TitleContainer = styled.div<{ isArabic?: boolean }>`
  text-align: ${(props) => (props.isArabic ? 'right' : 'left')};
  padding: 0 0 0 0;
`;

const ContentContainer = styled.div<{ isArabic?: boolean }>`
  direction: ${(props) => (props.isArabic ? 'rtl' : 'ltr')};
  text-align: ${(props) => (props.isArabic ? 'right' : 'left')};
`;

const CountryTitle = styled.h2<{ isArabic?: boolean }>`
  padding: 0 0 0 0;
  font-family: 'Inter SemiBold';
  font-size: 32px;
  font-weight: 900;
  color: black;
  margin-bottom: 1rem;
  text-align: ${(props) => (props.isArabic ? 'right' : 'left')};
`;

const LastUpdateLabel = styled.p<{ isArabic?: boolean }>`
  font-family: 'Inter SemiBold';
  font-size: 24px;
  color: black;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  text-align: ${(props) => (props.isArabic ? 'right' : 'left')};
`;

const TermsHeader = styled.h1<{ isArabic?: boolean }>`
  font-family: 'Inter SemiBold';
  font-size: 32px;
  color: black;
  text-align: ${(props) => (props.isArabic ? 'right' : 'left')};
  padding: 0 0 0 0;
`;

const TermTitle = styled.h2<{ isArabic?: boolean }>`
  padding: 0;
  font-family: 'Inter SemiBold';
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: ${(props) => (props.isArabic ? 'right' : 'left')};
`;

const StyledHr = styled.hr`
  width: 100%;
  margin: 2rem auto;
  border: none;
  border-top: 1px solid #000;
`;

const COUNTRY_CODES = {
  Germany: 'DE',
  United_arab_emirates: 'AE',
};

const replaceSpaceInString = (str = '', substitute = '') => str.replace(/\s/g, substitute);

const renderTermContent = ({ termDescription }: { termDescription: any[] }) => (
  /* 
    Removing Marker logic that was present in Account-Pages Repo.
    The logic searched for '@table_ and @board_' inside termDescription.raw array to render the table and board.
    However, the table and board are not present in PROD Terms of Use Page. Removing since unnecessary.
    The table and board atoms is available in this repo
  */

  <TermsRichText text={termDescription} />
);

const generateTermNavs = (terms: Term[], country: string): NavigationItem[] =>
  terms.map(({ term_title = { text: '' }, term_navigation_label = { text: null } }) => {
    const titleTerm = term_title.text;
    const titleNav = term_navigation_label?.text || titleTerm;
    return {
      text: titleNav,
      url: `#${replaceSpaceInString(titleTerm.toLowerCase(), '_')}_${country}`,
    };
  });

const TermsOfUsePage: React.FC<TermsOfUsePageProps> = ({ data }) => {
  const sliceData: Terms_Of_Use_Page = data.prismicTermsOfUsePage.data;
  const { title, body: blocksForEachCountry } = sliceData;

  // Generate country navigation
  const countryNavItems = blocksForEachCountry?.map((countryBlock) => ({
    text: countryBlock.primary?.country_nav_label?.text,
    url: `#${replaceSpaceInString(countryBlock.primary?.country_code || '', '_')}`,
  }));

  return (
    <Layout theme={Theme.White}>
      {/* Render title */}
      <SectionContainer>
        <TitleContainer>
          <TermsHeader>LES MILLS+ TERMS OF USE</TermsHeader>
          <TermsRichText text={title} />
        </TitleContainer>
      </SectionContainer>

      {/* Render country navigation */}
      <SectionContainer>
        <TermCountryNavigation navs={countryNavItems} />
      </SectionContainer>

      {/* Render terms for each country */}
      <SectionContainer>
        {blocksForEachCountry?.map((countryBlock, index) => {
          const { primary, items: terms } = countryBlock as ExtendedTermsOfUseBody;
          const countryCode = primary?.country_code || 'US';
          const termNavs = generateTermNavs(terms, countryCode);
          const isArabic = countryCode === COUNTRY_CODES.United_arab_emirates;

          return (
            <ContentContainer
              key={index}
              isArabic={isArabic}
              id={replaceSpaceInString(countryCode, '_')}
            >
              {/* Render country-specific content */}
              {primary?.terms_of_use_country_title && (
                <CountryTitle isArabic={isArabic}>
                  {primary.terms_of_use_country_title.text}
                </CountryTitle>
              )}
              {primary?.content_label && <TermsRichText text={primary.content_label.raw} />}
              <TermNavigation navs={termNavs} countryCode={countryCode} />
              <StyledHr />
              {primary?.last_update_label && (
                <LastUpdateLabel isArabic={isArabic}>
                  {primary.last_update_label.text}
                </LastUpdateLabel>
              )}
              {primary?.terms_of_use_country_description && (
                <TermsRichText text={primary.terms_of_use_country_description.raw} />
              )}

              {/* Render individual terms */}
              {terms?.map((term, termIndex) => {
                const termId = `${replaceSpaceInString(
                  term.term_title.text.toLowerCase(),
                  '_'
                )}_${countryCode}`;

                return (
                  <section key={termIndex} id={termId}>
                    <TermTitle isArabic={isArabic}>
                      {`${termIndex + 1}. ${term.term_title.text}`}
                    </TermTitle>
                    {renderTermContent({
                      termDescription: term.term_description.raw,
                    })}
                  </section>
                );
              })}
              <StyledHr />
            </ContentContainer>
          );
        })}
      </SectionContainer>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    prismicTermsOfUsePage(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicTermsOfUsePageDataBodyTermsOfUse {
            items {
              term_description {
                raw
                text
              }
              term_navigation_label {
                raw
                text
              }
              term_title {
                raw
                text
              }
            }
            primary {
              content_label {
                raw
                text
              }
              country_code
              country_nav_label {
                raw
                text
              }
              last_update_label {
                raw
                text
              }
              terms_of_use_country_description {
                raw
                text
              }
              terms_of_use_country_title {
                raw
                text
              }
            }
          }
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        title {
          text
          raw
        }
      }
    }
  }
`;

export default TermsOfUsePage;
