import React from 'react';
import styled from 'styled-components';
import { TermsRichText } from '@components/atoms';

interface TermBoardProps {
  data: {
    primary: {
      note_content: {
        raw: any;
      };
    };
  };
}

const BoardContainer = styled.div`
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
`;

const BoardContent = styled.div`
  font-size: 1rem;
  color: #1f2937;
  line-height: 1.5;
`;

const TermBoard: React.FC<TermBoardProps> = ({ data }) => {
  const { note_content: noteContent } = data.primary;

  return (
    <BoardContainer>
      <BoardContent>
        <TermsRichText text={noteContent} />
      </BoardContent>
    </BoardContainer>
  );
};

export default TermBoard;
