import React from 'react';
import styled from 'styled-components';
import { Link } from '@components/atoms';
import { COLORS } from '@themes';

interface TermNavigationProps {
  navs: Array<{
    text: string;
    url: string;
  }>;
  countryCode: string;
}

const NavigationContainer = styled.nav``;

const NavigationList = styled.ul`
  list-style-type: disc;
  padding-left: 1.5rem;
  margin: 0;
`;

const NavigationItem = styled.li`
  font-family: 'Inter Regular'
  font-size: 16px;
`;

const StyledLink = styled((props: any) => <Link {...props} />)`
  color: ${COLORS.green};
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const TermNavigation: React.FC<TermNavigationProps> = ({ navs, countryCode }) => (
  <NavigationContainer data-cy={`terms-nav-${countryCode}`}>
    <NavigationList>
      {navs.map((navItem, index) => (
        <NavigationItem key={index}>
          <StyledLink to={navItem.url}>{navItem.text}</StyledLink>
        </NavigationItem>
      ))}
    </NavigationList>
  </NavigationContainer>
);

export default TermNavigation;
